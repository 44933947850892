function loadAppComponent(path) {
    return () =>
        import(
            /* webpackChunkName: "app-page-[request]" */
            `@/views/app/${path}.vue`
        );
}

export default [
    {
        path: '/',
        component: loadAppComponent('Index'),
        redirect: '/dashboard',
        meta: {
            requiresAuth: true
        },
        children: [
            {
                path: '/dashboard',
                component: loadAppComponent('Dashboard'),
                name: 'dashboard.home'
            },
            {
                path: '/notifications',
                component: loadAppComponent('Notifications'),
                name: 'dashboard.notifications'
            },
            {
                path: '/users',
                component: loadAppComponent('users/Index'),
                name: 'dashboard.users',
                children: [
                    {
                        path: '',
                        component: loadAppComponent('users/ListUsers'),
                        name: 'dashboard.users.index',
                        props: true
                    },
                    {
                        path: '/users/:id/',
                        component: loadAppComponent('users/ViewUser'),
                        name: 'dashboard.users.view',
                        props: true
                    }
                ]
            },
            {
                path: 'routes',
                component: loadAppComponent('Routes'),
                name: 'dashboard.routes'
            },
            {
                path: 'reports',
                component: loadAppComponent('reports/Reports'),
                name: 'dashboard.reports'
            },
            {
                path: 'reports/preview',
                component: loadAppComponent('reports/UtilityReport'),
                name: 'dashboard.utility.report'
            },
            {
                path: 'billing',
                component: loadAppComponent('Billing'),
                name: 'dashboard.billing'
            },
            {
                path: 'buses',
                component: loadAppComponent('buses/Index'),
                name: 'dashboard.buses'
            },
            {
                path: '/account-settings',
                component: loadAppComponent('account/Index'),
                redirect: '/account-settings/profile',
                children: [
                    {
                        path: 'profile',
                        component: loadAppComponent('account/Profile'),
                        name: 'dashboard.account.profile'
                    },
                    {
                        path: 'password',
                        component: loadAppComponent('account/Password'),
                        name: 'dashboard.account.password'
                    },
                    {
                        path: 'email-notifications',
                        component: loadAppComponent('account/EmailNotifications'),
                        name: 'dashboard.account.email-notifications'
                    },
                    {
                        path: 'administration',
                        component: loadAppComponent('account/Administration'),
                        name: 'dashboard.account.administration'
                    },
                ]
            },
        ]
    }
]