import axios, { authorize, deleteHeader } from "@/services/axios";

const defaultState = () => {
    return {
        user: null,
        accessToken: localStorage.getItem("__access_token")
    };
};

const state = defaultState();

const getters = {
    user: state => {
        return state.user;
    },
    token: state => {
        return state.accessToken;
    }
};

const actions = {
    async init({ dispatch }) {
        dispatch("initUser");

        // dispatch('app/init', null, { root: true });
    },
    register({ commit }, payload) {
        return new Promise((resolve, reject) => {
            axios
                .post("/corporates", payload)
                .then(res => {
                    const {
                        token
                    } = res.data.token;

                    commit("AUTH_SUCCESS", res.data);

                    // commit('app/setWallet', { wallet: res.data.wallet }, { root: true })

                    authorize(token);

                    resolve();
                })
                .catch(err => {
                    localStorage.clear();

                    reject(err);
                });
        });
    },
    login({ commit }, payload) {
        return new Promise((resolve, reject) => {
            axios
                .post("/login", payload)
                .then(res => {
                    const {
                        token
                    } = res.data.token;

                    commit("AUTH_SUCCESS", res.data);

                    authorize(token);

                    // commit('app/setWallet', { wallet: res.data.wallet }, { root: true })

                    resolve();
                })
                .catch(err => {
                    localStorage.clear();

                    reject(err);
                });
        });
    },
    otpLogin({ commit }, payload) {
        return new Promise((resolve, reject) => {
            axios
              .patch(`/otp-login/${payload.referenceId}`, {otp_code: payload.otpCode, type: payload.type})
                .then(res => {
                    const {token} = res.data.token;
                    commit("AUTH_SUCCESS", res.data);
                    authorize(token);
                    resolve();
                })
                .catch(err => {
                    localStorage.clear();
                    reject(err);
                });
        });
    },
    initUser({ commit }) {
        const user = localStorage.getItem("user");

        if (user) {
            commit("SET_USER", JSON.parse(user));
        }
    },
    updateUser({ commit }, data) {
        return new Promise((resolve, reject) => {
            const userId = localStorage.getItem("__user_id");

            axios
                .patch(`/users/${userId}`, data)
                .then(res => {
                    commit("SET_USER", res.data);

                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    updateAvatar({ commit }, data) {
        return new Promise((resolve, reject) => {
            const userId = localStorage.getItem("__user_id");

            axios
                .patch(`/user/avatar/${userId}`, data)
                .then(res => {
                    commit("SET_USER", res.data);

                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    logout({ commit }) {
        return new Promise(resolve => {
            localStorage.clear();

            commit("LOGOUT");

            deleteHeader("Authorization");

            resolve(true);
        });
    }
};

const mutations = {
    AUTH_SUCCESS(state, data) {
        state.user = data;

        localStorage.setItem('user', JSON.stringify(data));
        localStorage.setItem('__user_id', data.id);
    },
    SET_USER(state, user) {
        state.user = user;

        localStorage.setItem('user',
            JSON.stringify({
                ...user,
                ...JSON.parse(localStorage.getItem('user'))
            })
        );
    },
    LOGOUT(state) {
        Object.assign(state, defaultState());
    },
    reset(state) {
        Object.assign(state, defaultState());
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
