function loadAppComponent(path) {
    return () =>
        import(
            /* webpackChunkName: "app-page-[request]" */
            `@/views/auth/${path}.vue`
        );
}

export default [
    {
        path: '/login',
        name: 'login',
        component: loadAppComponent('Login'),
        meta: {
            guest: true
        },
    },
    {
        path: '/register',
        name: 'register',
        component: loadAppComponent('Register'),
        meta: {
            guest: true
        },
        props: true
    },
    {
        path: '/forgot-password',
        name: 'forgot-password',
        component: loadAppComponent('ForgotPassword'),
        meta: {
            guest: true
        },
    },
    {
        path: '/password/:token',
        name: 'reset-password',
        component: loadAppComponent('ResetPassword'),
        props: true,
        meta: {
            guest: true
        },
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'PageNotFound',
        component: loadAppComponent('PageNotFound'),
    },
];