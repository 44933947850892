import mitt from 'mitt';
import App from './App.vue';
import store from './store';
import toastr from 'toastr';
import router from './router';
import { createApp } from 'vue';
import 'nprogress/nprogress.css';
import 'flatpickr/dist/flatpickr.css';
import { registerFilters } from "@/filters";
import flatPickr from 'vue-flatpickr-component';
import { VuelidatePlugin } from '@vuelidate/core';
import axios, { authorize } from "./services/axios";
import { register as registerComponents } from './utils/register-ui-components';
import VCalendar from 'v-calendar';
import eventTracker from './services/event-tracker'
import VueGtag from 'vue-gtag';
import {VueReCaptcha} from "vue-recaptcha-v3";


const emitter = mitt();
const app = createApp(App);

app.use(store);
app.use(router);
app.use(VuelidatePlugin);
app.use(VCalendar, {});
app.use(VueGtag, {
    config: { id: process.env.VUE_APP_GOOGLE_ANALYTICS_TAG_ID, send_page_view: false }
});
app.use(VueReCaptcha, { siteKey: process.env.VUE_APP_RECAPTCHA_SITE_KEY, loaderOptions: {autoHideBadge: true } });

registerComponents(app);
app.component('datepicker', flatPickr);

registerFilters(app);

app.config.globalProperties.emitter = emitter;
app.config.globalProperties.$http = axios;
app.config.globalProperties.$toast = toastr;
app.config.globalProperties.$eventTracker = eventTracker;

const user = JSON.parse(localStorage.getItem("user"));

if (user && user.token) {
    authorize(user.token.token);
}

store
    .dispatch("auth/init")
    .catch(() => {})
    .finally(() => {
        app.mount('#app');
    });
