import { event } from 'vue-gtag';

const logEvent = () => {
  return (ev, parameters) => event(`business-dashboard:${ev}`, parameters);
}

function analyticsEvents() {
  return {
    REGISTRATION_COMPLETED: 'successful_business_registration',
    REGISTRATION_STARTED: 'started_business_registration',
  }
}

export default {
  trackingEvent: analyticsEvents(),
  logEvent: logEvent()
}
